import React, { useEffect, useState, useContext } from 'react'

import EventsContext from './EventsContext'

const TOTAL_IMAGE_SOURCES = 1

const ImagesContext = React.createContext()
export function ImagesProvider (props) {
  const [images, setImages] = useState([])
  const [loaded, setLoaded] = useState(0)

  const events = useContext(EventsContext)

  function extractImages (data) {
    let extractedImages = images
    data.forEach(elem => {
      if (elem.images) {
        extractedImages = [...extractedImages, ...elem.images]
      }
    })
    setImages(extractedImages)
    setLoaded(loaded + 1)
  }

  useEffect(() => {
    extractImages(events.list)
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ImagesContext.Provider
      value={{
        list: images,
        loaded: loaded / TOTAL_IMAGE_SOURCES
      }}
    >
      {props.children}
    </ImagesContext.Provider>
  )
}

export default ImagesContext
