import React from 'react'

import css from './About.module.scss'

export default function AboutItem (props) {
  return (
    <div
      className={css.item}
      style={{
        width: props.width
      }}
    >
      <img
        src={props.image}
        className={css.image}
        alt='artsy showcase'
        style={{
          transform: `translate(-50%, -50%) scale(${props.scale})`,
          opacity: (props.current) ? 0.5 : 0
        }}
      />
      <div
        className={css.text}
      >
        {props.text}
      </div>
    </div>
  )
}
