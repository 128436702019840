import React, { useState, useContext, useEffect } from 'react'
import {
  BrowserRouter as Router, Route, useLocation  // eslint-disable-line no-unused-vars
} from 'react-router-dom'

import Long from './components/Long'
import Gallery from './components/gallery/Gallery'
import Overlay from './components/Overlay'
import EventOverlay from './components/event/EventOverlay'
import PageTranstion from './components/PageTransition'
import ScrollIndicator from './components/ScrollIndicator'

import EventsContext from './state/EventsContext'

// import css from './App.module.scss'

import './App.animation.css'
import { ROUTES, SECTIONS } from './components/utilities/defaults'

const duration = 300

export default function () {
  const location = useLocation()
  const [longVisited, setLongVisited] = useState(window.location.pathname === '/')
  const [shortVisited, setShortVisited] = useState(window.location.pathname === '/gallery')
  const [transitionOverlays, setTransitionOverlays] = useState(window.location.pathname === '/')
  const events = useContext(EventsContext)
  const [titleActive, setTitleActive] = useState(false)

  useEffect(() => {
    if (location.pathname === ROUTES.gallery) {
      setShortVisited(true)
    }
    if (location.pathname === ROUTES.home) {
      setLongVisited(true)
    }
  }, [location])



  return (
    <>
      <ScrollIndicator
        onSectionChange={(section) => {setTitleActive(section === SECTIONS[0])}}
      />
      <PageTranstion in={location.pathname === ROUTES.home} duration={duration}>
          <Long
            visited={longVisited}
            titleActive={titleActive && location.pathname === ROUTES.home}
          />
      </PageTranstion>
      <PageTranstion in={location.pathname === ROUTES.gallery} duration={duration}>
        {shortVisited &&
          <Gallery />
        }
      </PageTranstion>
      <PageTranstion in={location.pathname.startsWith(ROUTES.event)} duration={duration}>
        <Route
          path='/event/:id' render={({ match }) => {
            return (
              <Overlay
                link={`events-${match.params.id}`}
                transition={transitionOverlays}
                closeClicked={() => setTransitionOverlays(true)}
              >
                <EventOverlay event={events.list.find(event => event.slug.current === match.params.id)} />
              </Overlay>
            )
          }}
        />
      </PageTranstion>
      {/* <Route
        path='/event/:id' render={({ match }) => {
          return (
            <Overlay
              link={`events-${match.params.id}`}
              transition={transitionOverlays}
              closeClicked={() => setTransitionOverlays(true)}
            >
              <EventOverlay event={events.list.find(event => event.slug.current === match.params.id)} />
            </Overlay>
          )
        }}
      /> */}
    </>
  )
}
