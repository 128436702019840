import React, { useEffect, useState } from 'react'

import sanityClient from '../../sanityClient'
import MasonryImage from './MasonryImage'

import css from './Masonry.module.scss'

function randomBetween (min, max, randomSign) {
  const sign = (randomSign && Math.random() > 0.5) ? '-' : ''
  return sign + min + Math.random() * (max - min)
}

export default function Masonry (props) {
  const [imageRows, setImageRows] = useState([])
  // const [error, setError] = useState(false)

  async function fetchImages () {
    const query = '*[_type == "gallery"] {title, body, image, alt, video}'
    try {
      const images = await sanityClient.fetch(query, {})
      sortImages(images)
    } catch (e) {
      // setError(true)
    }
  }

  useEffect(() => {
    fetchImages()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function sortImages (array) {
    const rows = []
    let right = true
    for (let i = 0; i < array.length; i += 3) {
      rows.push(
        <div
          key={`row${i}`}
          className={`${css.row} ${(right) ? '' : css.reverseRow}`}
        >
          <div className={css.columnLeft}>
            <div className={css.big}>
              <MasonryImage
                big
                image={array[i] || undefined}
              />
            </div>
          </div>
          <div className={css.columnRight}>
            {array[i + 1] &&
              <div
                className={css.small}
                style={{
                  transform: `translate(${randomBetween(0, 30, true)}%, -${randomBetween(0, 50)}%)`,
                  margin: '-50px auto 100px'
                }}
              >
                <MasonryImage
                  image={array[i + 1] || undefined}
                />
              </div>
            }
            {array[i + 2] &&
              <div
                className={css.small}
                style={{
                  transform: `translate(${randomBetween(0, 30, true)}%, ${randomBetween(0, 50)}%)`
                }}
              >
                <MasonryImage
                  image={array[i + 2] || undefined}
                />
              </div>
            }
          </div>
        </div>
      )
      right = !right
    }
    setImageRows(rows)
  }

  return (
    <div className={css.container}>
      {imageRows.map(elem => elem)}
    </div>
  )
}
