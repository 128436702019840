import React, { useState, useEffect, useContext } from 'react'
import SideScroller from '../SideScroller'
import sanityClient from '../../sanityClient'

import TeamItem from './TeamItem'
import DimensionsContext from '../../state/DimensionsContext'

import css from './Team.module.scss'

export default function Team () {
  const dimensions = useContext(DimensionsContext)
  const [team, setTeam] = useState([])

  const negativeMargin = (dimensions.width < 700) ? 35 : (dimensions.width - 700) * 0.5 + 100
  const width = (dimensions.width < 600) ? Math.min(dimensions.width - negativeMargin, 700) : 700

  async function fetchTeam () {
    const query = '*[_type == "team"] | order(order) {name, description, image}'
    try {
      const team = await sanityClient.fetch(query, {})
      setTeam(team)
    } catch (e) {
      // setError(true)
    }
  }

  useEffect(() => {
    fetchTeam()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={css.container}>
      <SideScroller
        itemWidth={width}
        negativeMargin={negativeMargin}
        numItems={team.length}
        prefix='team'
        id='team'
        drawLine
        disableSnapOnDesktop
      >
        {team.map((member, i) =>
          <TeamItem
            key={member.name}
            member={member}
          />
        )
        }
      </SideScroller>
    </div>
  )
}
