import React, { useContext, useState, useEffect } from 'react'
import sanityClient, { urlFor } from '../../sanityClient'

import DimensionsContext from '../../state/DimensionsContext'

import SideScroller from '../SideScroller'
import AboutItem from './AboutItem'

import css from './About.module.scss'

const IMAGESCALES = [
  1,
  1.3,
  0.7,
  0.9
]

export default function About (props) {
  const dimensions = useContext(DimensionsContext)
  const [currentElement, setCurrentElement] = useState(0)
  const [abouts, setAbouts] = useState([])
  // const [error, setError] = useState(false)

  async function fetchAbout () {
    const query = '*[_type == "about"] {body, image}'
    try {
      const fetchedAbouts = await sanityClient.fetch(query, {})
      setAbouts(fetchedAbouts)
    } catch (e) {
      // setError(true)
    }
  }

  const negativeMargin = (dimensions.width < 800) ? 35 : 75
  const itemWidth = Math.min(dimensions.width - negativeMargin, 500)

  useEffect(() => {
    fetchAbout()
  }, [])

  return (
    <div
      style={{ ...props.style }}
      className={css.container}
    >
      <SideScroller
        itemWidth={itemWidth}
        negativeMargin={negativeMargin}
        numItems={abouts.length}
        setCurrentElement={setCurrentElement}
        id='about'
        scrollByClick
      >
        {abouts.map((about, i) => {
          return (
            <AboutItem
              key={`AboutItem${i}`}
              width={itemWidth}
              text={about.body}
              image={urlFor(about.image).width(500).url()}
              scale={IMAGESCALES[i]}
              current={currentElement === i}
              past={currentElement > i}
            />
          )
        })}
      </SideScroller>
    </div>
  )
}
