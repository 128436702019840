import React, { useEffect, useState } from 'react'

const DimensionsContext = React.createContext()

export function DimensionsProvider (props) {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  function handleResize () {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <DimensionsContext.Provider
      value={{ ...dimensions }}
    >
      {props.children}
    </DimensionsContext.Provider>
  )
}

export default DimensionsContext
