import React, { useEffect, useState } from 'react'

import css from './PageTransition.module.scss'

export default function PageTranstion (props) {
  const [reveal, setReveal] = useState(props.in)

  useEffect(() => {
    if (!reveal && props.in) {
      setTimeout(() => {
        setReveal(true)
      }, props.duration)
    }
    if (reveal && !props.in) {
      setReveal(false)
    }
  }, [props.in]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${css.container} ${(reveal) ? css.in : css.out}`}
      style={{
        pointerEvents: (props.in) ? 'all' : 'none'
      }}
    >
      {props.children}
    </div>
  )
}
