import React, { useContext, useRef, useState, useEffect } from 'react'
import { FaFacebookF } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import { MdMail } from 'react-icons/md'

// import Glitch from './Glitch'

import DimensionsContext from '../state/DimensionsContext'

import css from './Title.module.scss'

const colors = ['#d00000', '#006cde', 'green', '#e25100', '#ff8300']

const initialTimeout = 1500

export default function Title (props) {
  const dimensions = useContext(DimensionsContext)
  const color = useRef(colors[Math.floor(Math.random() * colors.length)])
  const [imageLoaded, setImageLoaded] = useState(false)
  const [siteLoaded, setSiteLoaded] = useState(false)
  const paths = useRef()
  const timeout = useRef(initialTimeout)

  const svg = useRef()

  function objectloaded () {
    setImageLoaded(true)
    svg.current.contentDocument.firstElementChild.style.stroke = color.current
  }

  useEffect(() => {
    window.addEventListener('load', () => {
      setSiteLoaded(true)
    })
  })

  useEffect(() => {
    if (imageLoaded && siteLoaded && !props.visited) {
      timeout.current = 3000
    }

    if (imageLoaded) {
      paths.current = svg.current.contentDocument.getElementsByClassName('st0')
      for (let i = 0; i < paths.current.length; i++) {
        paths.current.item(i).style.animationPlayState = 'paused'
      }
      svg.current.contentDocument.firstElementChild.classList.add('start')
    }

    if (imageLoaded && siteLoaded && props.visited) {
      setTimeout(() => {
        for (let i = 0; i < paths.current.length; i++) {
          paths.current.item(i).style.animationPlayState = (props.active) ? 'running' : 'paused'
          if (timeout.current !== 0) {
            timeout.current = 0
          }
        }
      }, timeout.current)
    }
  }, [imageLoaded, props.visited, siteLoaded, props.active])

  const size = 35

  return (
    <div
      className={css.container}
      style={{
        width: dimensions.width,
        height: dimensions.height,
        overflow: 'hidden'
      }}
      id='start'
    >
      <object
        ref={svg}
        aria-label='logo'
        onLoad={objectloaded}
        className={css.vivus}
        style={{
          opacity: (imageLoaded) ? 1 : 0
        }}
        type='image/svg+xml'
        data='/images/question2.svg'
        // data='/images/Portrait_electrical_animated.svg'
      />
      <div className={css.paragraph}>
        {/* <Glitch
          strong
          color={'green'}
        > */}
        <div className={css.analogdream}>analogdream</div>
        <div className={css.ev}>e.V.</div>
        <br />
        <div>
          <span>a collective of </span> <br />
          <span>engineers : artists : performers </span> <br />
          <span >fusing <br /> technology : performance </span>
          <br />
          <span className={css.art}>to create art</span>
        </div>
        <div className={css.icons}>
          <a 
            href='https://www.facebook.com/AnalogDream-106246634192283' 
            target="_blank"
            rel='noopener noreferrer'
            >
            <FaFacebookF
              size={size - 6}
            />
          </a>
          <a
            href='https://www.instagram.com/analogdream_/'
            target="_blank"
            rel='noopener noreferrer'
          >
            <AiFillInstagram
              size={size}
            />
          </a>
          <a 
            href='mailto:contact@analogdream.io'
            target="_blank"
            rel='noopener noreferrer'
          >
            <MdMail
              size={size}
            />
          </a>
        </div>
      </div>
    </div>
  )
}
