import React, { useState, useEffect, useRef } from 'react'
import ZoomableImage from '../ZoomableImage'

import { urlFor } from '../../sanityClient'

import css from './MasonryImage.module.scss'

export default function MasonryImage (props) {
  const [loaded, setLoaded] = useState(false)
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false)
  const [didIntersect, setDidIntersect] = useState(false)

  const zIndex = useRef(Math.round(Math.random() - 1))
  const ref = useRef()

  const size = (props.big) ? 500 : 350

  useEffect(() => {
    const observerOptions = {
      root: document.querySelector('#shortScroll'),
      rootMargin: '0px',
      threshold: 0
    }
    setTimeout(() => {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (!didIntersect && entry.isIntersecting) {
            setDidIntersect(true)
            observer.unobserve(ref.current)
          }
        })
      }, observerOptions)
      observer.observe(ref.current)
    }, 1000)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={css.container}
      ref={ref}
      style={{
        zIndex
      }}
    >
      <img
        src={urlFor(props.image.image).size(5, 5).url()}
        className={css.thumbnail}
        onLoad={() => setThumbnailLoaded(true)}
        alt='thumbnail'
        style={{
          opacity: (loaded) ? 0 : 1
        }}
      />
      {thumbnailLoaded &&
        <ZoomableImage
          src={(didIntersect) ? urlFor(props.image.image).size(size, size).url() : ''}
          hres={urlFor(props.image.image).width(1920).quality(80).url()}
          onLoad={() => setLoaded(true)}
          className={css.image}
          info={props.image.body}
          alt={props.image.alt}
          video={props.image.video}
        />
      }
    </div>
  )
}
