import React, { useRef } from 'react'
import isMobile from 'ismobilejs'

import About from './about/About'
import Events from './event/Events'
import Title from './Title.js'
import Team from './team/Team.js'
import Artworks from './artworks/Artworks'
import css from './Long.module.scss'

const isIos = isMobile().apple.device

export default function Long (props) {
  const container = useRef()

  return (
    <div
      className={css.container}
      style={{
        scrollSnapType: (isIos) ? '' : 'y mandatory'
      }}
      ref={container}
      id='long'
    >
      <Title
        visited={props.visited}
        active={props.titleActive}
      />
      <About />
      <Events />
      <Artworks />
      <Team />
    </div>
  )
}
