import React from 'react'

import { DimensionsProvider } from './DimensionsContext'
import { EventsProvider } from './EventsContext'
import { ImagesProvider } from './ImagesContext'
import { ZoomableProvider } from './ZoomableContext'

export default function RootContext (props) {
  return (
    <EventsProvider>
      <DimensionsProvider>
        <ImagesProvider>
          <ZoomableProvider>
            {props.children}
          </ZoomableProvider>
        </ImagesProvider>
      </DimensionsProvider>
    </EventsProvider>
  )
}
