import React from 'react'

import css from './Events.module.scss'

export default function NoEvent () {
  return (
    <div className={css.event}>
      <div className={css.title}>No upcoming events</div>
    </div>
  )
}
