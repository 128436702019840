import React from 'react'
import { urlFor } from '../../sanityClient'

import css from './Team.module.scss'

export default function TeamItem ({ member }) {
  return (
    <div className={css.member}>
      <img
        src={urlFor(member.image).format('jpg').width(600).url()}
        className={css.image}
        alt={member.name}
      />
      <div className={css.name}>
        {member.name}
      </div>
      <div className={css.description}>{member.description}</div>
    </div>
  )
}
