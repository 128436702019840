export const SECTIONS = [
  'start',
  'about',
  'events',
  'artworks',
  'team'
]

export const ROUTES = {
  gallery: '/gallery',
  home: '/',
  event: '/event'
}
