import React, { useRef, useState, useContext, useEffect } from 'react'
import ZoomableContext from '../state/ZoomableContext'
import { MdPlayArrow } from 'react-icons/md'

import css from './ZoomableImage.module.scss'

export default function ZoomableImage (props) {
  const zoomable = useContext(ZoomableContext)
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const imageRef = useRef()

  function openImage () {
    setOpen(true)
    zoomable.openImage(props.hres, props.info, props.alt, props.video)
  }

  useEffect(() => {
    if (open && !zoomable.open) {
      setOpen(false)
    }
  }, [zoomable]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.className}>
      <img
        onClick={openImage}
        src={props.src}
        alt={(loaded) ? props.alt : ''}
        ref={imageRef}
        onLoad={() => {
          props.onLoad()
          setLoaded(true)
        }}
        className={css.image}
        style={{
          transform: `scale(${open ? 0.5 : 1})`
        }}
      />
      {props.video &&
        <MdPlayArrow
          className={css.play}
        />
      }
    </div>
  )
}
