import React from 'react'
import { hydrate, render } from 'react-dom'
import {
  BrowserRouter as Router
} from 'react-router-dom'

import './index.css'
import App from './App'

import * as serviceWorker from './serviceWorker'

import RootContext from './state/RootContext'

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(
    <RootContext>
      <Router>
        <App />
      </Router>
    </RootContext>,
    document.getElementById('root')
  )
} else {
  render(
    <RootContext>
      <Router>
        <App />
      </Router>
    </RootContext>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
