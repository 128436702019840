import React, { useState, useEffect, useContext } from 'react'
import SideScroller from '../SideScroller'
import sanityClient from '../../sanityClient'

import Artwork from './Artwork'
import DimensionsContext from '../../state/DimensionsContext'

import css from './Artworks.module.scss'

export default function Artworks () {
  const dimensions = useContext(DimensionsContext)
  const [artworks, setArtworks] = useState([])

  const negativeMargin = (dimensions.width < 700) ? 35 : (dimensions.width - 700) * 0.5
  // const negativeMargin = 0
  const width = (dimensions.width < 600) ? Math.min(dimensions.width - negativeMargin, 400) : 400

  async function fetchArtworks () {
    const query = '*[_type == "artworks"] | order(date desc) {title, description, image, image2, size, material, date}'
    try {
      const artworks = await sanityClient.fetch(query, {})
      setArtworks(artworks)
    } catch (e) {
      // setError(true)
    }
  }

  useEffect(() => {
    fetchArtworks()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={css.container}>
      <SideScroller
        itemWidth={width}
        negativeMargin={negativeMargin}
        numItems={artworks.length}
        prefix='artworks'
        id='artworks'
        drawLine
        disableSnapOnDesktop
      >
        {artworks.map((artwork, i) =>
          <Artwork
            key={artwork.title}
            artwork={artwork}
          />
        )
        }
      </SideScroller>
    </div>
  )
}
