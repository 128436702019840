import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react'
import BlockContent from '@sanity/block-content-to-react'
import { dateStringLong } from '../utilities/Dates'
import { urlFor } from '../../sanityClient'

import css from './EventOverlay.module.scss'
import ZoomableImage from '../ZoomableImage'

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const mapStyles = {
  width: '100%',
  height: '0',
  paddingBottom: '100%'
}

function EventOverlay (props) {
  const { event } = props
  const [ activeMarker, setActiveMarker ] = useState(undefined)

  function handleMarkerClick (props, marker) {
    setActiveMarker(marker)
  }

  const dateString = dateStringLong(event)

  return (
    <div className={css.container}>
      {props.event &&
        <>
          <div className={css.backdrop}></div>
          <div className={css.innerContainer}>
            <div className={css.title}>{event.title}</div>
            <div className={css.date}>{dateString}</div>
            <div className={css.location}>{event.location}</div>
            <div className={css.description}>
              <BlockContent
                blocks={event.body}
                serializers={serializers}
              />
            </div>
            <div className={css.images}>
              {event.images && event.images.map(image =>
                <ZoomableImage
                  key={image._key}
                  src={urlFor(image).size(200, 200).url()}
                  hres={urlFor(image).width(1920).url()}
                  onLoad={() => {}}
                  className={css.image}
                  alt={event.title}
                />
              )}
            </div>
            {event.lng && event.lat &&
              <Map
              google={props.google}
              styles={props.mapStyles}
              disableDefaultUI={true}
              gestureHandling='cooperative'
              zoom={15}
              style={mapStyles}
              initialCenter={{ lat: event.lat, lng: event.lng}}
            >
              <Marker
                title={event.title}
                name='Event Location'
                position={{ lat: event.lat, lng: event.lng}}
                onClick={handleMarkerClick}
              />
              <InfoWindow
                marker={activeMarker}
                visible={!!activeMarker}
              >
                <div>
                  <p>{event.location}</p>
                </div>
              </InfoWindow>
              </Map>
            }
          </div>
        </>}
    </div>
  )
}

EventOverlay.defaultProps = {
  mapStyles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ]
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCTtfrzvEAJcKQ3OcckD26VaspRCFwMYbA'
})(EventOverlay)
