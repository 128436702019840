import React, { useState, useContext } from 'react'
import {
  BrowserRouter as Router, Switch, Route, Link // eslint-disable-line no-unused-vars
} from 'react-router-dom'

import { urlFor } from '../../sanityClient'

import css from './Events.module.scss'
import { dateStringShort, yearOfEvent } from '../utilities/Dates'
import DimensionsContext from '../../state/DimensionsContext'

const MINDEG = 25
const MAXDEG = 45

function randomDeg () {
  const negative = (Math.random() > 0.5)
  const randomDeg = MINDEG + Math.random() * (MAXDEG - MINDEG)
  return (negative) ? -randomDeg : randomDeg
}

export default function Event (props) {
  const { event } = props
  const dimensions = useContext(DimensionsContext)
  const dateString = dateStringShort(event)
  const year = yearOfEvent(event)

  const [rotation] = useState({
    x: Math.random(),
    y: Math.random(),
    z: Math.random(),
    deg: randomDeg()
  })

  const imageSize = (dimensions.width < 500) ? 250 : 300

  return (
    <Link to={`/event/${event.slug.current}`}>
      <div className={css.event} id={`event-${event.slug.current}`}>

        <div className={css.titleContainer}>
          <div className={css.title}>
            {event.title}
          </div>
          <div className={css.date}>{dateString} {year}</div>
        </div>
        <img
          src={urlFor(event.mainImage).size(imageSize, imageSize).url()}
          className={css.image}
          alt={event.title}
          style={{
            transform: `translate3d(-50%, -50%, -200px) rotate3d(${rotation.x}, ${rotation.y}, ${rotation.z}, ${rotation.deg}deg)`
          }}
        />
        {/* <div className={css.pointer}>
          <IoMdArrowDropup
            className={css.icon}
            size={25}
          />
        </div> */}
        {/* <div className={css.place}>{event.location[2]}</div> */}
        {/* <div className={css.street}>{location[1]}, {location[2]}</div> */}
      </div>
    </Link>
  )
}
