import React, { useEffect, useState } from 'react'

import sanityClient from '../sanityClient'

const EventsContext = React.createContext()
export function EventsProvider (props) {
  const [events, setEvents] = useState([])
  const [error, setError] = useState(false)

  async function fetchEvents () {
    const query = '*[_type == "event"] {title, slug, body, images, location, lng, lat, type, start, end, mainImage}'
    try {
      const fetchedEvents = await sanityClient.fetch(query, {})
      const sortedEvents = fetchedEvents.sort((a, b) => {
        return new Date(b.end) - new Date(a.end)
      })
      setEvents(sortedEvents)
      console.log(sortedEvents)
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <EventsContext.Provider
      value={{
        list: events,
        error
      }}
    >
      {props.children}
    </EventsContext.Provider>
  )
}

export default EventsContext
