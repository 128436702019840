import React from 'react'

import css from './Gallery.module.scss'
import Masonry from './Masonry'

export default function Gallery () {
  return (
    <div className={css.container} id='shortScroll'>
      <div className={css.wordCloud}>
        <h1>What we create</h1>
      </div>
      <div className={css.masonry}>
        <Masonry />
      </div>
    </div>
  )
}
