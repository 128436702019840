import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import DimensionsContext from '../state/DimensionsContext'
import ZoomableContext from '../state/ZoomableContext'

import EasingFunctions from './utilities/EasingFunctions'
import { SECTIONS, ROUTES } from './utilities/defaults'

import css from './ScrollIndicator.module.scss'

const threshold = 0.01

let lastScrollPos = 0
let scrollSpeed = 0
let scrollInProgress = false

const sectionChangeTimeout = 1300

let element

export default function ScrollIndicator (props) {
  const dimensions = useContext(DimensionsContext)
  const zoomable = useContext(ZoomableContext)
  const history = useHistory()
  const location = useLocation()
  const [ sectionChanged, setSectionChanged ] = useState(true)
  const [ toSection, setToSection ] = useState(undefined)
  const accurateDimensions = useRef(dimensions)

  const activeSection = useRef(SECTIONS[0])
  const scrollInitiatedByTouch = useRef(false)

  function observeScrollPosition () {
    const scrollPos = element.scrollTop
    const sectionIndex = SECTIONS.indexOf(activeSection.current)

    if (scrollPos > accurateDimensions.current.height * (sectionIndex + 1)) {
      element.style.overflow = 'hidden'
      element.scrollTo(0, accurateDimensions.current.height * (sectionIndex + 1))
    } else if (scrollPos < accurateDimensions.current.height * (sectionIndex - 1)) {
      element.style.overflow = 'hidden'
      element.scrollTo(0, accurateDimensions.current.height * (sectionIndex - 1))
    }
    window.requestAnimationFrame(observeScrollPosition)
  }

  useEffect(() => {
    accurateDimensions.current = dimensions
    if (element) {
      element.scrollTo(0, dimensions.height * SECTIONS.indexOf(activeSection.current))
      setToSection(undefined)
    }
  }, [dimensions])

  const scrollTo = (next, force) => {
    if (!scrollInProgress) {
      const startPos = element.scrollTop
      if (force || startPos % accurateDimensions.current.height !== 0) {
        scrollInProgress = true
        element.style.overflow = 'hidden'
        const snapType = element.style.scrollSnapType
        element.style.scrollSnapType = ''
        let endPos = 0

        const offset = force ? 1 : 0
        const duration = force ? 800 : 500
        const ease = force ? EasingFunctions.easeInCubic : EasingFunctions.easeOutCubic
        if (next) {
          endPos = Math.ceil((startPos + offset) / accurateDimensions.current.height) * accurateDimensions.current.height
        } else {
          endPos = Math.floor((startPos - offset) / accurateDimensions.current.height) * accurateDimensions.current.height
        }

        const start = performance.now()
        const easeOut = () => {
          let timeFraction = (performance.now() - start) / duration
          if (timeFraction > 1) timeFraction = 1

          const progress = ease(timeFraction)
          const scrollTo = startPos + (endPos - startPos) * progress

          element.scroll(0, scrollTo)

          if (timeFraction < 1) {
            window.requestAnimationFrame(easeOut)
          } else {
            scrollInProgress = false
            element.style.overflow = 'scroll'
            element.style.scrollSnapType = snapType
            element.focus()
          }
        }
        window.requestAnimationFrame(easeOut)
      }
    }
  }

  useEffect(() => {
    element = document.getElementById('long')
    observeScrollPosition()
    element.addEventListener('touchend', (event) => {
      scrollTo(scrollSpeed > 0)
    })

    element.addEventListener('scroll', (event) => {
      scrollSpeed = element.scrollTop - lastScrollPos
      lastScrollPos = element.scrollTop
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.onSectionChange(activeSection.current)
  }, [sectionChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const observerOptions = {
      root: document.querySelector('#long'),
      rootMargin: '0px',
      threshold: [threshold, 1 - threshold, 1]
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.intersectionRatio === 1) {
            SECTIONS.forEach((section) => {
              if (section !== entry.target.id) {
                const sectionElement = document.getElementById(section)
                sectionElement.style.transition = 'opacity 0ms'
                sectionElement.style.opacity = 0
              }
            })
            entry.target.style.transition = 'opacity 800ms 400ms ease'
            entry.target.style.opacity = 1
            if (entry.target.id !== activeSection.current) {
              activeSection.current = entry.target.id
              setSectionChanged(true)
              setToSection(undefined)
              element.style.overflow = 'hidden'

              setTimeout(() => {
                setSectionChanged(false)
                scrollInitiatedByTouch.current = false
                element.style.overflow = 'scroll'
              }, sectionChangeTimeout)
            }
          } else if (entry.intersectionRatio > 0.5) {
            if (entry.intersectionRatio >= 1 - threshold) {
              if (activeSection.current === entry.target.id) {
                if (!scrollInitiatedByTouch.current) {
                  setToSection(undefined)
                }
              }
            }
          } else {
            if (entry.intersectionRatio >= threshold) {
              setToSection(entry.target.id)
            }
          }
        }
      })
    }, observerOptions)
    SECTIONS.forEach((section, i) => {
      const sectionElement = document.getElementById(section)
      observer.observe(sectionElement)
      if (i !== 0) {
        element.style.opacity = 1
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSectionChanged(true)
    setTimeout(() => {
      setSectionChanged(false)
    }, sectionChangeTimeout + 800)
  }, [location])

  function back () {
    if (zoomable.open) {
      zoomable.closeImage()
    } else {
      history.push(ROUTES.home)
    }
  }

  const sectionIndex = SECTIONS.indexOf(activeSection.current)
  const nextSection = SECTIONS[sectionIndex + 1] || 'end'
  const previousSection = SECTIONS[sectionIndex - 1] || 'end'

  return (
    <div className={css.container}>
      {SECTIONS.map((section) => {
        const scrollAway = (toSection && section !== toSection) ||
                            (!sectionChanged && (activeSection.current === section || section !== previousSection)) ||
                            (sectionChanged) ||
                            location.pathname !== ROUTES.home
        const enlarge = (toSection === previousSection && previousSection === section) || (sectionChanged && activeSection.current === section)
        return (
          <div
            className={css.topContainer}
            key={`top${section}`}
            onClick={() => { scrollTo(false, true) }}
            onTouchEnd={() => { scrollTo(false, true) }}
            onTouchStart={() => {
              setToSection(SECTIONS[SECTIONS.indexOf(activeSection.current) - 1])
              scrollInitiatedByTouch.current = true
            }}
            style={{
              pointerEvents: location.pathname === ROUTES.home && previousSection === section ? 'all' : 'none'
            }}
          >
            <div
              className={`${css.top} ${enlarge ? css.enlarge : scrollAway ? css.scrollAway : ''}`}
              style={{
                opacity: sectionChanged || section !== previousSection ? 0 : 1
              }}
            >
              <div
                className={`${css.topText} ${enlarge ? css.enlarge : ''}`}
              >
                {section}
              </div>
              <div className={css.topLine} />
            </div>
          </div>
        )
      })}
      {SECTIONS.map((section) => {
        const scrollAway = (toSection && section !== toSection) || // Indication at beginning of scrolling
                            (!sectionChanged && (activeSection.current === section || section !== nextSection)) || // If not relevant for current section
                            (sectionChanged) ||
                            location.pathname !== ROUTES.home
        const enlarge = (toSection === nextSection && nextSection === section) || (sectionChanged && activeSection.current === section)
        return (
          <div
            className={css.bottomContainer}
            key={`bottom${section}`}
            onClick={() => { scrollTo(true, true) }}
            onTouchEnd={() => { scrollTo(true, true) }}
            style={{
              pointerEvents: location.pathname === ROUTES.home && nextSection === section ? 'all' : 'none'
            }}
            onTouchStart={() => {
              setToSection(SECTIONS[SECTIONS.indexOf(activeSection.current) + 1])
              scrollInitiatedByTouch.current = true
            }}
          >
            <div
              className={`${css.bottom} ${enlarge ? css.enlarge : scrollAway ? css.scrollAway : ''}`}
              style={{
                opacity: sectionChanged || section !== nextSection ? 0 : 1
              }}
            >
              <div
                className={`${css.bottomText} ${enlarge ? css.enlarge : ''}`}
              >
                {section}
              </div>
              <div className={css.bottomLine} />
            </div>
          </div>
        )
      })}
      <div
        className={`${css.linkToImagesContainer} ${location.pathname === ROUTES.gallery ? css.hover : ''}`}
        onClick={() => { history.push(ROUTES.gallery) }}
        onTouchEnd={() => { history.push(ROUTES.gallery) }}
        style={{
          opacity: location.pathname === ROUTES.gallery ? 0 : 1
        }}
      >
        <div
          className={css.linkToImages}
          style={{
            transform: `translate(${((sectionChanged && location.pathname !== ROUTES.gallery) || toSection) ? '50px, -50px' : '0, 0'})`,
            opacity: (sectionChanged && location.pathname !== ROUTES.gallery) ? 0 : 1
          }}
        >
          <div className={css.linkToImagesText}>
            gallery
          </div>
          <div className={css.linkToImagesLine} />
        </div>
      </div>
      <div
        className={`${css.backContainer} ${location.pathname === ROUTES.home ? css.hover : ''}`}
        onClick={back}
        onTouchEnd={back}
        style={{
          opacity: location.pathname === ROUTES.home ? 0 : 1
        }}
      >
        <div
          className={css.back}
          style={{
            transform: `translate(${((sectionChanged && location.pathname !== ROUTES.home) || toSection) ? '-50px, -50px' : '0, 0'})`,
            opacity: (sectionChanged && location.pathname !== ROUTES.home) ? 0 : 1
          }}
        >
          <div className={css.backText}>
            back
          </div>
          <div className={css.backLine} />
        </div>
      </div>
    </div>
  )
}
