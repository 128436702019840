import React, { useContext } from 'react'

import SideScroller from '../SideScroller'
import NoEvent from './NoEvent'
import Event from './Event'

import DimensionsContext from '../../state/DimensionsContext'
import EventsContext from '../../state/EventsContext'

import css from './Events.module.scss'

const today = new Date()

export default function Events (props) {
  const events = useContext(EventsContext)
  const dimensions = useContext(DimensionsContext)

  const negativeMargin = (dimensions.width < 700) ? 35 : (dimensions.width - 700) * 0.5 + 100
  const width = (dimensions.width < 600) ? Math.min(dimensions.width - negativeMargin, 400) : 400

  const nearestDate = (events.list.len > 0) ? new Date(events.list[0].end) : 0
  const eventUpcoming = today > nearestDate

  return (
    <div
      className={css.container}
      style={{ ...props.style }}
    >
      <SideScroller
        itemWidth={width}
        negativeMargin={negativeMargin}
        numItems={events.list.length + ((eventUpcoming) ? 0 : 1)}
        prefix='event'
        id='events'
        drawLine
        disableSnapOnDesktop
      >
        {[
          ...[(eventUpcoming)
            ? undefined
            : <NoEvent key='noEvent' />
          ],
          ...events.list.map((event, i) =>
            <Event
              key={`${i}${event.title}`}
              event={event}
            />
          )
        ]}
      </SideScroller>
    </div>
  )
}
