import React, { createContext, useState, useRef } from 'react'
import { MdInfo, MdClear } from 'react-icons/md'

import css from './ZoomableContext.module.scss'

const ZoomableContext = createContext()

export function ZoomableProvider (props) {
  const [image, setImage] = useState({
    image: undefined,
    info: undefined,
    alt: undefined,
    video: undefined
  })
  const [imageLoaded, setImageLoaded] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const previousImage = useRef(undefined)

  function openImage (image, info, alt, video) {
    setImage({
      image, info, alt, video
    })
    setShowImage(true)
    setShowInfo(false)
    if (previousImage.current === image) {
      setImageLoaded(true)
    }
    previousImage.current = image
  }

  function closeImage () {
    setShowImage(false)
    setImageLoaded(false)
    setShowInfo(false)
  }

  return (
    <ZoomableContext.Provider
      value={{
        openImage,
        closeImage,
        open: (showImage)
      }}
    >
      {props.children}
      <div
        className={css.overlay}
        style={{
          opacity: (showImage) ? 1 : 0,
          pointerEvents: (showImage) ? 'all' : 'none'
        }}
        onClick={closeImage}
      >
        {!image.video &&
          <img
          src={image.image}
          alt={image.alt}
          onLoad={() => { setImageLoaded(true) }}
          className={css.image}
          style={{
            opacity: showInfo ? 0.3 : imageLoaded ? 1 : 0
          }}
        />
        }
        {!image.video && image.info &&
         <>
          <MdInfo
            onClick={(e) => {
              e.stopPropagation()
              setShowInfo(!showInfo)
            }}
            size={40}
            className={css.infoButton}
          />
          <div
            className={css.info}
            style={{
              opacity: showInfo ? 1 : 0,

            }}
          >
            {image.info}
          </div>
         </>
        }
        {image.video && showImage &&
          <iframe
            title='Embedded Vimeo Video'
            src={`https://player.vimeo.com/video/${image.video}?playsinline=true`}
            width='100%'
            height='100%'
            frameborder='0'
            allowfullscreen
          />
        }
        {image.video &&
         <>
          <MdClear
            onClick={closeImage}
            size={40}
            className={css.infoButton}
          />
          <div
            className={css.info}
            style={{
              opacity: showInfo ? 1 : 0,

            }}
          >
            {image.info}
          </div>
         </>
        }
      </div>
    </ZoomableContext.Provider>
  )
}

export default ZoomableContext
