import React, { useContext, useEffect, useRef, useState } from 'react'
import ScrollBooster from 'scrollbooster'
import isMobile from 'ismobilejs'

import EasingFunctions from './utilities/EasingFunctions'

import DimensionsContext from '../state/DimensionsContext'

import css from './SideScroller.module.scss'

const mobile = isMobile().any

export default function SideScroller (props) {
  const dimensions = useContext(DimensionsContext)
  const scrollInProgress = useRef(false)
  const scrollContainer = useRef()
  const scrollPosition = useRef(0)
  const currentElement = useRef(0)
  const updateFunction = useRef()
  const running = useRef(false)
  const sb = useRef(undefined)
  const mousePositionNext = useRef(false)
  const currentCursorNext = useRef(false)
  const [showCursorNext, setShowCursorNext] = useState(false)
  const [stopScrollDirection, setStopScrollDirection] = useState(-1)
  const [dragging, setDragging] = useState(false)

  const padding = dimensions.width * 0.5 - props.itemWidth * 0.5
  const innerContainerWidth = props.itemWidth * props.numItems + padding * (props.numItems - 1) - props.negativeMargin * (props.numItems - 1)

  useEffect(() => {
    updateFunction.current = () => {
      const scrollLeft = scrollContainer.current.scrollLeft
      if (scrollPosition.current !== scrollLeft) {
        scrollPosition.current = scrollLeft
        const atElement = scrollLeft * (1 / (innerContainerWidth + padding))
        const newElement = Math.round(atElement / (1 / props.numItems))
        if (newElement !== currentElement.current) {
          currentElement.current = newElement
          props.setCurrentElement(newElement)
          if (newElement === 0) {
            setStopScrollDirection(-1)
          } else if (newElement === props.numItems - 1) {
            setStopScrollDirection(1)
          } else {
            setStopScrollDirection(0)
          }
        }
      }
      window.requestAnimationFrame(updateFunction.current)
    }
    scrollPosition.current = -1

    if (props.setCurrentElement && !running.current) {
      running.current = true
      window.requestAnimationFrame(updateFunction.current)
    }
  }, [dimensions, props.numItems]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.disableSnapOnDesktop && !mobile) {
      sb.current = new ScrollBooster({
        viewport: document.getElementById(props.id),
        scrollMode: 'native',
        onPointerDown: () => {
          if (props.disableSnapOnDesktop) {
            setDragging(true)
          }
        },
        onPointerUp: () => {
          if (props.disableSnapOnDesktop) {
            setDragging(false)
          }
        }
      })
    }
    if (!props.disableSnapOnDesktop) {
      updateCursor()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function trackMouse (e) {
    mousePositionNext.current = (e.clientX > dimensions.width * 0.5)
  }

  function updateCursor () {
    if (currentCursorNext.current && !mousePositionNext.current) {
      currentCursorNext.current = false
      setShowCursorNext(false)
    } else if (!currentCursorNext.current && mousePositionNext.current) {
      currentCursorNext.current = true
      setShowCursorNext(true)
    }
    window.requestAnimationFrame(updateCursor)
  }

  function scrollByClick (e) {
    if (!mobile) {
      const next = (e.clientX > dimensions.width * 0.5)
      if ((next && stopScrollDirection !== 1) || (!next && stopScrollDirection !== -1)) {
        if (props.scrollByClick && !scrollInProgress.current) {
          const element = document.getElementById(props.id)
          const startPos = element.scrollLeft

          scrollInProgress.current = true
          element.style.overflowX = 'hidden'
          const snapType = element.style.scrollSnapType
          element.style.scrollSnapType = 'none'
          let endPos = 0

          const duration = 600
          const ease = EasingFunctions.easeInOutCubic

          const elemSize = props.itemWidth + padding - props.negativeMargin
          if (next) {
            endPos = (Math.ceil(startPos / elemSize) + 1) * elemSize
          } else {
            endPos = (Math.ceil(startPos / elemSize) - 1) * elemSize
          }

          const start = performance.now()
          const easeOut = () => {
            let timeFraction = (performance.now() - start) / duration
            if (timeFraction > 1) timeFraction = 1

            const progress = ease(timeFraction)
            const scrollTo = startPos + (endPos - startPos) * progress

            element.scrollTo(scrollTo, 0)

            if (timeFraction < 1) {
              window.requestAnimationFrame(easeOut)
            } else {
              scrollInProgress.current = false
              element.style.overflowX = 'scroll'
              element.style.scrollSnapType = snapType
            }
          }
          window.requestAnimationFrame(easeOut)
        }
      }
    }
  }

  let cursor = 'initial'
  if (!props.disableSnapOnDesktop) {
    if (showCursorNext) {
      if (stopScrollDirection === 1) {
        cursor = 'url("/icons/navigate_next_stop.png"), auto'
      } else {
        cursor = 'url("/icons/navigate_next.png") 0 6, auto'
      }
    } else {
      if (stopScrollDirection === -1) {
        cursor = 'url("/icons/navigate_before_stop.png"), auto'
      } else {
        cursor = 'url("/icons/navigate_before.png") 0 6, auto'
      }
    }
  } else {
    cursor = dragging ? 'url("/icons/navigate_grab.png"), auto' : 'url("/icons/navigate_drag.png"), auto'
  }

  return (
    <div
      className={css.outerContainer}
      ref={scrollContainer}
      id={props.id}
      onClick={scrollByClick}
      onMouseMove={!props.disableSnapOnDesktop ? trackMouse : () => {}}
      style={{
        cursor,
        scrollSnapType: (props.disableSnapOnDesktop && !mobile) ? 'none' : 'x mandatory'
      }}
    >
      <div
        className={css.innerContainer}
        style={{
          width: innerContainerWidth,
          padding: `0 ${padding}px`
        }}
      >
        {props.children.filter(e => e !== undefined).map((child, i) => {
          return (
            <div
              className={css.item}
              key={`scrollItem${i}`}
              style={{
                width: props.itemWidth,
                marginRight: padding - props.negativeMargin
              }}
            >
              {child}
            </div>
          )
        })}
      </div>
    </div>
  )
}
