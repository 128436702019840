import React, { useState, useEffect } from 'react'

import css from './Overlay.module.scss'

export default function Overlay (props) {
  const [reveal, setReveal] = useState(!props.transition)

  useEffect(() => {
    if (props.transition) {
      setReveal(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={css.container}
      style={{
        opacity: (props.transition && !reveal) ? 0 : 1
      }}
    >
      {props.children}
    </div>
  )
}
