import React, { useState } from 'react'
import { urlFor } from '../../sanityClient'

import css from './Artworks.module.scss'

export default function Artwork ({ artwork }) {
  const [clicked, setClicked] = useState(false)
  return (
    <div className={css.artwork}>
      <img
        src={urlFor(artwork.image).ignoreImageParams().size(500, 500).bg('000').fit('fill').url()}
        className={css.image}
        alt={artwork.title}
      />
      <img
        src={urlFor(artwork.image2).ignoreImageParams().size(500, 500).bg('000').fit('fill').url()}
        className={css.image2}
        alt={artwork.title + ' lit from the inside'}
        onClick={() => { setClicked(!clicked) }}
        style={{
          opacity: clicked ? 1 : 0
        }}
      />
      <div className={css.title}>
        {artwork.title}
      </div>
      <div className={css.size}>
        {artwork.size}
      </div>
      <div className={css.material}>
        {artwork.material}
      </div>
      <div className={css.description}>{artwork.description}</div>
    </div>
  )
}
