export const MONTHS = [
  'January', 'February', 'March',
  'April', 'May', 'June', 'July',
  'August', 'September', 'October',
  'November', 'December'
]

export function sameDay (d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
}

export function sameMonth (d1, d2) {
  return d1.getMonth() === d2.getMonth()
}

export function getHours (date) {
  let minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  return `${date.getHours()}:${minutes}`
}

export function dateStringLong (event) {
  let dateString = ''
  if (event) {
    const start = new Date(event.start)
    const end = new Date(event.end)
    const isSingleDate = (start.getTime() === end.getTime())
    const onSameDay = sameDay(start, end)
    const inSameMonth = sameMonth(start, end)

    dateString = `${start.getDate()}. ${MONTHS[start.getMonth()]} ${getHours(start)}`
    if (isSingleDate || onSameDay) {
      dateString = `${dateString} ${MONTHS[start.getMonth()]}`
    } else if (inSameMonth) {
      dateString = `${dateString} - ${end.getDate()}. ${MONTHS[start.getMonth()]} ${getHours(end)}`
    } else {
      dateString = `${dateString} - ${end.getDate()} ${MONTHS[end.getMonth()]} ${getHours(end)}`
    }
  }
  return dateString
}

export function dateStringShort (event) {
  let dateString = ''
  if (event) {
    const start = new Date(event.start)
    const end = new Date(event.end)
    const isSingleDate = (start.getTime() === end.getTime())
    const onSameDay = sameDay(start, end)
    const inSameMonth = sameMonth(start, end)

    dateString = `${start.getDate()}.`
    if (isSingleDate || onSameDay) {
      dateString = `${dateString} ${MONTHS[start.getMonth()]}`
    } else if (inSameMonth) {
      dateString = `${dateString} - ${end.getDate()}. ${MONTHS[start.getMonth()]}`
    } else {
      dateString = `${dateString} ${MONTHS[start.getMonth()]} - ${end.getDate()} ${MONTHS[end.getMonth()]}`
    }
  }
  return dateString
}

export function yearOfEvent (event) {
  return new Date(event.end).getFullYear()
}
